import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/Layout";
import { colors, presets, themeStyles } from "../utils/theme";

import Button from "../components/Button";
import { rhythm } from "../utils/typography";

import _ from "lodash";
import { navigate } from "gatsby-link";
import { styles as buttonStyles } from "../components/Button";

export function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const styles = {
  title: {
    color: colors.darkGreen,
    textAlign: "center",
    fontSize: rhythm(1.5),
    marginTop: rhythm(2),
  },
  subText: {
    textAlign: "center",
    fontSize: rhythm(0.8),
    marginTop: rhythm(2),
    maxWidth: "600px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  subTextBold: {
    textAlign: "center",
    fontSize: rhythm(0.8),
    fontWeight: "600",
    marginTop: rhythm(2),
    maxWidth: "600px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttonContainer: {
    marginTop: rhythm(1),
    display: "none",
    [presets.Tablet]: {
      display: "grid",
    },
  },
  form: {
    justifyContent: "space-between",
    margin: `0 auto ${rhythm(5)} auto`,
    padding: rhythm(2),
    border: `1px solid ${colors.blueGreen}`,
    borderRadius: "20px",
    width: "85%",
    [presets.Tablet]: {
      width: "60%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      maxWidth: "600px",
    },
  },
  inputContainer: {
    width: "100%",
    margin: "0.3rem auto",
    [presets.Tablet]: {
      margin: "0.3rem",
      width: "47%",
    },
  },
  inputRadioContainer: {
    width: "100%",
    margin: "0.3rem auto",
    [presets.Tablet]: {
      margin: "0.3rem",
      width: "47%",
    },
  },
  radio: {
    width: "25%",
    border: `1px solid ${colors.mediumGrey}`,
    padding: `${rhythm(0.4)} ${rhythm(0.1)} ${rhythm(0.4)} ${rhythm(0.9)}`,
    borderRadius: "5px",
    fontSize: rhythm(0.75),
    "::placeholder": {
      color: colors.mediumGrey,
    },
  },
  input: {
    width: "100%",
    border: `1px solid ${colors.mediumGrey}`,
    padding: `${rhythm(0.4)} ${rhythm(0.1)} ${rhythm(0.4)} ${rhythm(0.9)}`,
    borderRadius: "5px",
    fontSize: rhythm(0.75),
    "::placeholder": {
      color: colors.mediumGrey,
    },
  },
  buttonContainer: {
    width: "100%",
  },
  button: {
    marginTop: rhythm(0.5),
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  contact: {
    display: "none",
  },
};

export function ContactForm() {
  const [formState, setFormState] = useState({
    firstName: "",
    companyName: "",
    amount: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const handlePhoneNumberInput = (value) => {
    if (value) {
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;

      if (phoneNumberLength < 4) {
        return phoneNumber;
      }
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleOnChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "phoneNumber") {
      value = handlePhoneNumberInput(value);
    }
    setFormState({
      ...formState,
      [e.target.name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(() => console.error());
  };

  return (
    <form
      name="svb-cash"
      method="post"
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      css={styles.form}
      onSubmit={handleSubmit}
    >
      <input
        className="input"
        type="hidden"
        name="form-name"
        value="svb-cash"
        required={false}
      />
      <div hidden>
        <input className="input" name="bot-field" onChange={handleOnChange} />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First Name"
          onChange={handleOnChange}
          required={true}
        />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          onChange={handleOnChange}
          required={true}
        />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="text"
          name="companyName"
          id="companyName"
          placeholder="Company or Fund Name"
          onChange={handleOnChange}
          required={true}
        />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="text"
          name="amount"
          id="amount"
          placeholder="Amount of Capital Needed"
          onChange={handleOnChange}
          required={true}
        />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          onChange={handleOnChange}
          required={true}
        />
      </div>
      <div className="field" css={styles.inputContainer}>
        <input
          className="input"
          css={styles.input}
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Phone Number"
          onChange={handleOnChange}
          value={_.get(formState, "phoneNumber")}
          required={true}
          maxLength={14}
          minLength={14}
        />
      </div>

      {/*      <div className="field" css={styles.inputRadioContainer}>
        <input
          type="radio"
          id="payroll"
          name="need"
          value="payroll"
          className="input"
          css={styles.radio}
          checked
        />
        <label for="payroll">Make Payroll</label>
      </div>

      <div className="field" css={styles.inputRadioContainer}>
        <input
          type="radio"
          id="debt"
          name="need"
          value="debt"
          css={styles.radio}
        />
        <label for="debt">Cover Debt Payments</label>
      </div>

      <div className="field" css={styles.inputRadioContainer}>
        <input
          type="radio"
          id="operations"
          name="need"
          value="operations"
          className="input"
          css={styles.radio}
        />
        <label for="operations">Operations</label>
      </div>
*/}
      <div className="field" css={styles.buttonContainer}>
        <button
          className="button is-link"
          type="submit"
          css={[buttonStyles.primary, styles.button]}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export const SvbCash = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const {
    showHeaderLinks,
    title,
    subTitle,
    metadataTitle,
    metadataDescription,
    products,
    contactFormHeader,
  } = frontmatter;

  return (
    <Layout
      showLinks={showHeaderLinks}
      metadataTitle={metadataTitle}
      metadataDescription={metadataDescription}
    >
      <div css={[themeStyles.contentWidth, styles.buttonContainer]}></div>
      <div>
        <h2 style={styles.title}>Have cash stuck at SVB?</h2>
        <dd css={styles.subText}>
          LoanSnap and our financial partners have allocated over $1 billion
          towards helping startups and funds. Make payroll, handle debt
          payments, and keep your startup or fund running.{" "}
        </dd>

        <dd css={styles.subTextBold}>
          Get access to millions of dollars in days
        </dd>
      </div>
      <ContactForm />
    </Layout>
  );
};

SvbCash.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        showHeaderLinks: PropTypes.bool,
        title: PropTypes.string,
        metadataTitle: PropTypes.string,
        metadataDescription: PropTypes.string,
        products: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            stripePaymentUrl: PropTypes.string,
            price: PropTypes.number,
            includedOptions: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string,
                subTexts: PropTypes.arrayOf(
                  PropTypes.shape({
                    value: PropTypes.string,
                  })
                ),
              })
            ),
          })
        ),
        contactFormHeader: PropTypes.string,
      }),
    }),
  }),
};

export default SvbCash;

export const pageQuery = graphql`
  query SvbCashQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "svb-cash" } }) {
      frontmatter {
        showHeaderLinks
        metadataTitle
        metadataDescription
        title
        description
        products {
          title
          price
          stripePaymentUrl
          includedOptions {
            text
            subTexts {
              value
            }
          }
        }
        contactFormHeader
      }
    }
  }
`;
